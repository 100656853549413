@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Open Sans Light'), local('OpenSans-Light'), url('../assets/fonts/open-sans-300-normal.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'), url('../assets/fonts/open-sans-300-italic.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url('../assets/fonts/open-sans-600-normal.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url('../assets/fonts/open-sans-600-italic.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,600,300italic,600italic");*/

/*
	Catalyst by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

:root {
  --page-background: #fff;
  --page-background-alt: #fafafa;
  --text-color: #000;
  --alt-text-color: #FFF;
  --link-color: #920f1e;
  --header-color: #2a2a2a;
  --caption-gray: #757575;
  --accent-gray: #eee;
  --transparent-gray-accent: rgba(59, 35, 35, 0.05);
  --transparent-button-hover: rgba(144, 144, 144, 0.05);
  --articel-footer: #5a5a5a;
  --action-block-color: #ddd;
  --footer-background: #1d2726;
  --footer-text: #8C8C8C;
  --footer-social: #666;
  --footer-social-hover: #fff;
  --error-color: darkred;
  --error-background: rgba(218, 70, 70, 0.726);
  --success-color: green;
  --success-background: rgba(145, 221, 126, 0.726);
  --notice-color: darkgray;
  --notice-background:  rgba(128, 128, 128, 0.726);
  --feature-color: #484848;
  --form-background: white;
  --form-disabled: lightgray;
  --button-hover: #283634;
  --image-footer: rgba(29, 39, 38, 0.75);
  --feature-header: #ffffff8f;
  --feature-footer: rgba(29, 39, 38, 0.75);
  --feature-footer-text: white;
  --nav-text: #ccc;
  --light-gray-background: #e6e6e6;
}

@media (prefers-color-scheme: dark) {
  :root {
    --page-background: rgb(27, 27, 27);
    --page-background-alt: #252525;
    --accent-gray: rgb(204, 204, 204);
    --text-color: rgb(238, 230, 230);
    --alt-text-color: black;
    --header-color: #dfdfdf;
    --footer-background: #444240;
    --footer-text: var(--footer-social);
    --footer-social: rgb(214, 214, 214);
    --footer-social-hover: rgb(218, 218, 218);
    --articel-footer: #9c9c9c;
    --feature-color: #919191;
    --form-background: rgb(27,27,27);
    --transparent-button-hover: rgba(73, 73, 73, 0.05);
    --form-disabled: rgb(95, 94, 94);
    --button-hover: #444240;
    --feature-header: #3c3c3c8f;
    --feature-footer: var(--feature-header);
    --nav-text: var(--footer-social);
    --light-gray-background: var(--footer-background);
  }

  *{
    box-sizing: border-box;
  }

  .checklist li::before {
    filter: invert(1);
  }
  
  .extern-link::after {
    filter: invert(1);
  }

  #main a {
    font-weight: 600;
  }

  .wp-block-table.is-style-stripes tbody tr:nth-child(2n+1){
    background: var(--footer-background);
  }

  .wp-image-431, .wp-image-439 {
    background: var(--text-color);
  }

  .wp-image-426 {
    background: white;
    padding: 10px;
  }

  .wp-image-61 {
    background: white;
    padding: 5px 10px;
  }

  .mail-body {
    color: black;
  }
  
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

.ribbon {
  width: 290px;
  height: 250px;
  overflow: hidden;
  position: absolute;
  top: 20px;
  z-index: 9999;
  right: 3px;
  transform: rotate(45deg);
}

.ribbon a span{
  background-color: var(--link-color);
  display: block;
  color: white;
  text-decoration: none;
}

.help-block {
  background-color: #abc;
  display: block;
  padding: 5px 2px;
  border: 1px solid;
  margin: 0px 0px 1em 0px;
}

.formerror{
  background-color: var(--error-background);
  display: block;
  padding: 2px 10px;
  border: 1px solid;
  margin: 0px 0px 1em 0px;
}

.help-block .list-unstyled{
  margin: 0;
}

body {
  line-height: 1;
}

html {
  scroll-behavior: smooth;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
select,
textarea {
  appearance: none;
}

/* Basic */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: var(--page-background);
  transition: background 0.2s ease-in-out;
}

body.is-preload *,
body.is-preload *::before,
body.is-preload *::after {
  animation: none !important;
  transition: none !important;
}

body,
input,
select,
textarea {
  color: var(--text-color);
  transition: color 0.2s ease-in-out;
  font-family: "Open Sans", sans-serif;
  font-size: 13pt;
  font-weight: 300;
  line-height: 1.75em;
}

a {
  color: var(--link-color);
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

strong,
b {
  font-weight: 600;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 2em 0;
}

.content p{
  text-align: justify;
}

/*
.content li{
  text-align: justify;
}
*/


.special .button {
  width: 370px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header-color);
  transition: color 0.2s ease-in-out;
  font-weight: 600;
  letter-spacing: 0.125em;
  line-height: 1em;
  margin: 0 0 1em 0;
  text-transform: uppercase;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-size: 1.5em;
  line-height: 1.75em;
}

h3 {
  font-size: 1.25em;
  line-height: 1.5em;
}

h4 {
  font-size: 1.1em;
  line-height: 1.5em;
}

h5 {
  font-size: 0.9em;
  line-height: 1.5em;
}

h6 {
  font-size: 0.7em;
  line-height: 1.5em;
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

hr {
  border: 0;
  border-bottom: solid 3px var(--accent-gray);
  margin: 3em 0;
}

hr.major {
  margin: 5em 0;
}

blockquote {
  border-left: solid 8px var(--accent-gray);
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em;
}

pre {
  -webkit-overflow-scrolling: touch;
  background: var(--transparent-gray-accent);
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 0 0 2em 0;
  overflow-x: auto;
  padding: 1em 1.5em;
}

code {
  background:var(--transparent-gray-accent);
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Row */

.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: stretch;
}

.row > * {
  box-sizing: border-box;
}

.row.gtr-uniform > * > :last-child {
  margin-bottom: 0;
}

.row.aln-left {
  justify-content: flex-start;
}

.row.aln-center {
  justify-content: center;
}

.row.aln-right {
  justify-content: flex-end;
}

.row.aln-top {
  align-items: flex-start;
}

.row.aln-middle {
  align-items: center;
}

.row.aln-bottom {
  align-items: flex-end;
}

.row > .imp {
  order: -1;
}

.row > .col-1 {
  width: 8.33333%;
}

.row > .off-1 {
  margin-left: 8.33333%;
}

.row > .col-2 {
  width: 16.66667%;
}

.row > .off-2 {
  margin-left: 16.66667%;
}

.row > .col-3 {
  width: 25%;
}

.row > .off-3 {
  margin-left: 25%;
}

.row > .col-4 {
  width: 33.33333%;
  max-width: 400px;
}

.row > .off-4 {
  margin-left: 33.33333%;
}

.row > .col-5 {
  width: 41.66667%;
}

.row > .off-5 {
  margin-left: 41.66667%;
}

.row > .col-6 {
  width: 50%;
}

.row > .off-6 {
  margin-left: 50%;
}

.row > .col-7 {
  width: 58.33333%;
}

.row > .off-7 {
  margin-left: 58.33333%;
}

.row > .col-8 {
  width: 66.66667%;
}

.row > .off-8 {
  margin-left: 66.66667%;
}

.row > .col-9 {
  width: 75%;
}

.row > .off-9 {
  margin-left: 75%;
}

.row > .col-10 {
  width: 83.33333%;
}

.row > .off-10 {
  margin-left: 83.33333%;
}

.row > .col-11 {
  width: 91.66667%;
}

.row > .off-11 {
  margin-left: 91.66667%;
}

.row > .col-12 {
  width: 100%;
}

.row > .off-12 {
  margin-left: 100%;
}

.row.gtr-0 {
  margin-top: 0;
  margin-left: 0;
}

.row.gtr-0 > * {
  padding: 0 0 0 0;
}

.row.gtr-0.gtr-uniform {
  margin-top: 0;
}

.row.gtr-0.gtr-uniform > * {
  padding-top: 0;
}

.row.gtr-25 {
  margin-top: 0;
  margin-left: -0.75em;
}

.row.gtr-25 > * {
  padding: 0 0 0 0.75em;
}

.row.gtr-25.gtr-uniform {
  margin-top: -0.75em;
}

.row.gtr-25.gtr-uniform > * {
  padding-top: 0.75em;
}

.row.gtr-50 {
  margin-top: 0;
  margin-left: -1.5em;
}

.row.gtr-50 > * {
  padding: 0 0 0 1.5em;
}

.row.gtr-50.gtr-uniform {
  margin-top: -1.5em;
}

.row.gtr-50.gtr-uniform > * {
  padding-top: 1.5em;
}

.row {
  margin-top: 0;
  margin-left: -3em;
}

.row > * {
  padding: 0 0 0 3em;
}

.row.gtr-uniform {
  margin-top: -3em;
}

.row.gtr-uniform > * {
  padding-top: 3em;
}

.row.gtr-150 {
  margin-top: 0;
  margin-left: -4.5em;
}

.row.gtr-150 > * {
  padding: 0 0 0 4.5em;
}

.row.gtr-150.gtr-uniform {
  margin-top: -4.5em;
}

.row.gtr-150.gtr-uniform > * {
  padding-top: 4.5em;
}

.row.gtr-200 {
  margin-top: 0;
  margin-left: -6em;
}

.row.gtr-200 > * {
  padding: 0 0 0 6em;
}

.row.gtr-200.gtr-uniform {
  margin-top: -6em;
}

.row.gtr-200.gtr-uniform > * {
  padding-top: 6em;
}

.textLogo {
  margin: 10px 0 0 0;
  transition: opacity 500ms ease-in;
}

.smallNote {
  font-size: 90%;
  font-weight: normal;
}

article {
  margin-top: 60px;
  margin-bottom: 120px;
}

article footer {
  color: var(--articel-footer);
  font-size: 10pt;
  display: block;
  clear: both;
  margin-top: 35px;
}

.features-page img, .features-page video{
  border: 3px solid var(--accent-gray);
}

.features-page figure.wp-block-image{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.container img {
  max-width: 100%;
  height: auto;
}

[class^="wp-block-"] figcaption {
  color: var(--caption-gray);
  font-size: 12px;
  font-size: 0.857142857rem;
  line-height: 2;
  font-style: italic;
  text-align: left;
}

.centeredsmall {
  max-width: 400px;
  margin: 0 auto;
}

figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

figure.size-full{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.textLogohidden {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

.action-block {
  text-align: center;
  max-width: 336px;
  margin: 100px auto 0;
}

.action-block .top {
  padding: 22px 0 15px 0;
  color: var(--text-color);
}

.action-block span {
  display: block;
}

.action-block .button, .action-block .wp-block-button {
  color: var(--action-block-color);
  background: var(--link-color);
  border: 1px solid var(--link-color);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  white-space: normal;
  line-height: 4em;
  padding: 0 1em;
}

.action-block .price {
  font-size: 20pt;
  margin: 8px 0 8px 0;
}

.checklist {
  max-width: 300px;
  margin: 15px auto 0;
}

.register .checklist {
  max-width: 600px;
  margin: 15px auto 30px;
}

.checklist li {
  list-style-type: none;
  margin-top: 5px;
}

.checklist li::before {
  content: '';
  display: inline-block;
  height: 22px;
  width: 20px;
  background-size: 20px;
  background-image: url("../assets/img/checkbox.svg");
  background-repeat: no-repeat;
  background-position: 0 5px;
  background-origin: content-box;
  margin-right: 5px;
}

.info-box h3 {
  margin: 15px 0 0 0;
}

.info-box {
  border: 2px solid var(--text-color);
  padding: 15px;
}

.info-box img {
  margin-right: 5px;
}

.small-text {
  font-size: 80%;
  line-height: 18px;
  margin: 3px 0;
}

.legal-small-print {
  font-size: 60%;
}

.price-label {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 115%;
}

.tax-info {
  font-size: 60%;
}

.info-box .float-left {
  float: left;
}

.nav-tabs {
  list-style: none;
}

.nav-tabs li {
  display: inline-block;
}


.wp-block-navigation-item__content[href]:not(:where(
  [href^="#"],
  [href^="/"]:not([href^="//"]),
  [href*="//telani.net"],
  [href*="//localhost:8000"]
))::after 
,.extern-link::after {
  background-image: url('../assets/img/extern.svg');
  background-size: 14px;
  content: " ";
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 2px;
  position: relative;
  top: 2px;
}

#nav .wp-block-navigation-item__content[href]:not(:where(
  [href^="#"],
  [href^="/"]:not([href^="//"]),
  [href*="//telani.net"],
  [href*="//localhost:8000"]
)):hover::after
,#nav .extern-link:hover::after {
	filter: invert(100%);
}


#nav .wp-block-navigation-item__content[href]:not(:where(
  [href^="#"],
  [href^="/"]:not([href^="//"]),
  [href*="//telani.net"],
  [href*="//localhost:8000"]
))::after
,#nav .extern-link::after {
  filter: invert(80%);
  transition: filter 0.2s ease-in-out;
}

.clickable-row{
  cursor: pointer;
}

.clickable-row:hover{
  background: #c1c1c1;
}

.forgot-link {
  margin-top: 30px;
  font-size: 80%;
}

.referenceLargeContainer img {
  display: block;
  margin: 0 auto;
}

.center, .content p.center{
  text-align: center;
}

.content__gdprLegal,
.copyright-notice {
  font-size: 85%;
  color: var(--caption-gray);
  text-align: center;
  display: block;
  margin-top: 20px;
}

#mc_embed_signup {
  clear: left;
  width: 100%;
}

.alert{
  padding: 5px 15px;
  border: 1px solid var(--notice-color);
  background: var(--notice-background);
}

.alert.alert-success{
  border: 1px solid var(--success-color);
  background: var(--success-background);

}

.alert.alert-danger{
  border: 1px solid var(--error-color);
  background: var(--error-background);
}

.list-unstyled{
  padding-left: 0;
  list-style: none;
}

.alert ul{
  margin: 0;
}

#mergeRow-gdpr p {
  text-align: justify;
}

#mc_embed_signup label {
  font-weight: bold;
}

#banner img {
  max-width: 100%;
}

#banner .inner img{
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  padding: 5px;
}

.clearfix {
  clear: both;
}

.has-error .g-recaptcha, .has-error .h-captcha {
  border: 1px solid var(--error-color);
  padding: 7px 5px;
}

.g-recaptcha, .h-captcha{
  margin: .25em 0;
}

.wp-block-image figure.alignright{
  float: right;
  padding: 0;
  display: table;
  margin: 0.857142857rem 0 0.857142857rem 1.714285714rem;
}

.wp-block-image figure.alignleft{
  float: left;
  padding: 0;
  display: table;
  margin: 0.857142857rem 1.714285714rem 0.857142857rem 0;
}

.emphasis{
  font-weight: bold;
}

.license-type-header{
  font-size: 8pt;
  text-transform: none;
  font-style: italic;
}

.register .small-text{
  margin-bottom: 16px;
}

@media (min-width: 920px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column:not(:first-child) {
    margin-left: var(--wp--style--block-gap,1em);
  }   
}

@media (min-width: 600px) and (max-width: 920px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column:nth-child(2n) {
    margin: 0 auto 15px auto;
  }
}

#vorlagen-offer ul{
  margin-bottom: auto;
}
#vorlagen-offer{
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
}

#vorlagen-offer .col-4{
  width: auto;
  display: flex;
  flex: 1 0 250px;
  flex-direction: column;
}

/* Custom */

.laptopArt {
  width: 100px;
  float: left;
}


.multiline-button{
  height: auto;
  min-height: 4em;
  white-space: normal;
  text-overflow: initial;
}

.fullwidthbutton{
  width: 100%;
  padding: 0;
}

.vorlagen-block {
  border: solid 3px var(--accent-gray);
  padding: 15px;
}

#vorlagen-offer{
  margin: 0;
  flex-wrap: nowrap;
}

.vorlagen-block .button{
  margin-top: 15px;
}

.vorlagen-block .checklist, #content .vorlagen-block .checklist li{
  padding: 0;
  text-align: left;
}

.vorlagen-block .action-button{
  margin-top: 15px;
}


.vorlagen-block p{
  margin: 0;
  text-align: left;
}

.vorlagen-block .checklist{
  margin: 0 auto;
}

.register{
  display: flex;
}

.register section{
  padding: 0 30px;
  text-align: left;
}

#content .register p,#content .register li {
  text-align: left;
}

.register input[type="submit"]{
  display: block;
  margin: 30px auto 0;
}

.register input[type="checkbox"] + span::before{
  background: var(--page-background);
}

.register .centeredsmall{
  padding: 20px;
  max-width: 440px;
  border: solid 1px var(--articel-footer);
  background: var(--page-background-alt); 
}


@media screen and (max-width: 1680px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-wide {
    order: -1;
  }

  .row > .col-1-wide {
    width: 8.33333%;
  }

  .row > .off-1-wide {
    margin-left: 8.33333%;
  }

  .row > .col-2-wide {
    width: 16.66667%;
  }

  .row > .off-2-wide {
    margin-left: 16.66667%;
  }

  .row > .col-3-wide {
    width: 25%;
  }

  .row > .off-3-wide {
    margin-left: 25%;
  }

  .row > .col-4-wide {
    width: 33.33333%;
  }

  .row > .off-4-wide {
    margin-left: 33.33333%;
  }

  .row > .col-5-wide {
    width: 41.66667%;
  }

  .row > .off-5-wide {
    margin-left: 41.66667%;
  }

  .row > .col-6-wide {
    width: 50%;
  }

  .row > .off-6-wide {
    margin-left: 50%;
  }

  .row > .col-7-wide {
    width: 58.33333%;
  }

  .row > .off-7-wide {
    margin-left: 58.33333%;
  }

  .row > .col-8-wide {
    width: 66.66667%;
  }

  .row > .off-8-wide {
    margin-left: 66.66667%;
  }

  .row > .col-9-wide {
    width: 75%;
  }

  .row > .off-9-wide {
    margin-left: 75%;
  }

  .row > .col-10-wide {
    width: 83.33333%;
  }

  .row > .off-10-wide {
    margin-left: 83.33333%;
  }

  .row > .col-11-wide {
    width: 91.66667%;
  }

  .row > .off-11-wide {
    margin-left: 91.66667%;
  }

  .row > .col-12-wide {
    width: 100%;
  }

  .row > .off-12-wide {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.75em;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.75em;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.75em;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.75em;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -1.5em;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 1.5em;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -1.5em;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 1.5em;
  }

  .row {
    margin-top: 0;
    margin-left: -3em;
  }

  .row > * {
    padding: 0 0 0 3em;
  }

  .row.gtr-uniform {
    margin-top: -3em;
  }

  .row.gtr-uniform > * {
    padding-top: 3em;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -4.5em;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 4.5em;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -4.5em;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 4.5em;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -6em;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 6em;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -6em;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 6em;
  }
}

@media screen and (max-width: 1280px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-normal {
    order: -1;
  }

  .row > .col-1-normal {
    width: 8.33333%;
  }

  .row > .off-1-normal {
    margin-left: 8.33333%;
  }

  .row > .col-2-normal {
    width: 16.66667%;
  }

  .row > .off-2-normal {
    margin-left: 16.66667%;
  }

  .row > .col-3-normal {
    width: 25%;
  }

  .row > .off-3-normal {
    margin-left: 25%;
  }

  .row > .col-4-normal {
    width: 33.33333%;
  }

  .row > .off-4-normal {
    margin-left: 33.33333%;
  }

  .row > .col-5-normal {
    width: 41.66667%;
  }

  .row > .off-5-normal {
    margin-left: 41.66667%;
  }

  .row > .col-6-normal {
    width: 50%;
  }

  .row > .off-6-normal {
    margin-left: 50%;
  }

  .row > .col-7-normal {
    width: 58.33333%;
  }

  .row > .off-7-normal {
    margin-left: 58.33333%;
  }

  .row > .col-8-normal {
    width: 66.66667%;
  }

  .row > .off-8-normal {
    margin-left: 66.66667%;
  }

  .row > .col-9-normal {
    width: 75%;
  }

  .row > .off-9-normal {
    margin-left: 75%;
  }

  .row > .col-10-normal {
    width: 83.33333%;
  }

  .row > .off-10-normal {
    margin-left: 83.33333%;
  }

  .row > .col-11-normal {
    width: 91.66667%;
  }

  .row > .off-11-normal {
    margin-left: 91.66667%;
  }

  .row > .col-12-normal {
    width: 100%;
  }

  .row > .off-12-normal {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.5em;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.5em;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.5em;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.5em;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -1em;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 1em;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -1em;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 1em;
  }

  .row {
    margin-top: 0;
    margin-left: -2em;
  }

  .row > * {
    padding: 0 0 0 2em;
  }

  .row.gtr-uniform {
    margin-top: -2em;
  }

  .row.gtr-uniform > * {
    padding-top: 2em;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -3em;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 3em;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -3em;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 3em;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -4em;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 4em;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -4em;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 4em;
  }
}

@media screen and (max-width: 980px) {

  .ribbon {
    top: 63px;
    right: 12px;
    z-index: 9999;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-narrow {
    order: -1;
  }

  .row > .col-1-narrow {
    width: 8.33333%;
  }

  .row > .off-1-narrow {
    margin-left: 8.33333%;
  }

  .row > .col-2-narrow {
    width: 16.66667%;
  }

  .row > .off-2-narrow {
    margin-left: 16.66667%;
  }

  .row > .col-3-narrow {
    width: 25%;
  }

  .row > .off-3-narrow {
    margin-left: 25%;
  }

  .row > .col-4-narrow {
    width: 33.33333%;
  }

  .row > .off-4-narrow {
    margin-left: 33.33333%;
  }

  .row > .col-5-narrow {
    width: 41.66667%;
  }

  .row > .off-5-narrow {
    margin-left: 41.66667%;
  }

  .row > .col-6-narrow {
    width: 50%;
  }

  .row > .off-6-narrow {
    margin-left: 50%;
  }

  .row > .col-7-narrow {
    width: 58.33333%;
  }

  .row > .off-7-narrow {
    margin-left: 58.33333%;
  }

  .row > .col-8-narrow {
    width: 66.66667%;
  }

  .row > .off-8-narrow {
    margin-left: 66.66667%;
  }

  .row > .col-9-narrow {
    width: 75%;
  }

  .row > .off-9-narrow {
    margin-left: 75%;
  }

  .row > .col-10-narrow {
    width: 83.33333%;
  }

  .row > .off-10-narrow {
    margin-left: 83.33333%;
  }

  .row > .col-11-narrow {
    width: 91.66667%;
  }

  .row > .off-11-narrow {
    margin-left: 91.66667%;
  }

  .row > .col-12-narrow {
    width: 50%;
  }

  .row > .off-12-narrow {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.5em;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.5em;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.5em;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.5em;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -1em;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 1em;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -1em;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 1em;
  }

  .row {
    margin-top: 0;
    margin-left: -2em;
  }

  .row > * {
    padding: 0 0 0 2em;
  }

  .row.gtr-uniform {
    margin-top: -2em;
  }

  .row.gtr-uniform > * {
    padding-top: 2em;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -3em;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 3em;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -3em;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 3em;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -4em;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 4em;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -4em;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 4em;
  }
}

@media screen and (max-width: 736px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .register {
    flex-direction: column;
  }

  .register .option-feature-description{
    display: none;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-mobile {
    order: -1;
  }

  .row > .col-1-mobile {
    width: 8.33333%;
  }

  .row > .off-1-mobile {
    margin-left: 8.33333%;
  }

  .row > .col-2-mobile {
    width: 16.66667%;
  }

  .row > .off-2-mobile {
    margin-left: 16.66667%;
  }

  .row > .col-3-mobile {
    width: 25%;
  }

  .row > .off-3-mobile {
    margin-left: 25%;
  }

  .row > .col-4-mobile {
    width: 33.33333%;
  }

  .row > .off-4-mobile {
    margin-left: 33.33333%;
  }

  .row > .col-5-mobile {
    width: 41.66667%;
  }

  .row > .off-5-mobile {
    margin-left: 41.66667%;
  }

  .row > .col-6-mobile {
    width: 50%;
  }

  .row > .off-6-mobile {
    margin-left: 50%;
  }

  .row > .col-7-mobile {
    width: 58.33333%;
  }

  .row > .off-7-mobile {
    margin-left: 58.33333%;
  }

  .row > .col-8-mobile {
    width: 66.66667%;
  }

  .row > .off-8-mobile {
    margin-left: 66.66667%;
  }

  .row > .col-9-mobile {
    width: 75%;
  }

  .row > .off-9-mobile {
    margin-left: 75%;
  }

  .row > .col-10-mobile {
    width: 83.33333%;
  }

  .row > .off-10-mobile {
    margin-left: 83.33333%;
  }

  .row > .col-11-mobile {
    width: 91.66667%;
  }

  .row > .off-11-mobile {
    margin-left: 91.66667%;
  }

  .row > .col-12-mobile {
    width: 100%;
  }

  .row > .off-12-mobile {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.5em;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.5em;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.5em;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.5em;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -1em;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 1em;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -1em;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 1em;
  }

  .row {
    margin-top: 0;
    margin-left: -2em;
  }

  .row > * {
    padding: 0 0 0 2em;
  }

  .row.gtr-uniform {
    margin-top: -2em;
  }

  .row.gtr-uniform > * {
    padding-top: 2em;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -3em;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 3em;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -3em;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 3em;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -4em;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 4em;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -4em;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 4em;
  }
}

@media screen and (max-width: 480px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-mobilep {
    order: -1;
  }

  .row > .col-1-mobilep {
    width: 8.33333%;
  }

  .row > .off-1-mobilep {
    margin-left: 8.33333%;
  }

  .row > .col-2-mobilep {
    width: 16.66667%;
  }

  .row > .off-2-mobilep {
    margin-left: 16.66667%;
  }

  .row > .col-3-mobilep {
    width: 25%;
  }

  .row > .off-3-mobilep {
    margin-left: 25%;
  }

  .row > .col-4-mobilep {
    width: 33.33333%;
  }

  .row > .off-4-mobilep {
    margin-left: 33.33333%;
  }

  .row > .col-5-mobilep {
    width: 41.66667%;
  }

  .row > .off-5-mobilep {
    margin-left: 41.66667%;
  }

  .row > .col-6-mobilep {
    width: 50%;
  }

  .row > .off-6-mobilep {
    margin-left: 50%;
  }

  .row > .col-7-mobilep {
    width: 58.33333%;
  }

  .row > .off-7-mobilep {
    margin-left: 58.33333%;
  }

  .row > .col-8-mobilep {
    width: 66.66667%;
  }

  .row > .off-8-mobilep {
    margin-left: 66.66667%;
  }

  .row > .col-9-mobilep {
    width: 75%;
  }

  .row > .off-9-mobilep {
    margin-left: 75%;
  }

  .row > .col-10-mobilep {
    width: 83.33333%;
  }

  .row > .off-10-mobilep {
    margin-left: 83.33333%;
  }

  .row > .col-11-mobilep {
    width: 91.66667%;
  }

  .row > .off-11-mobilep {
    margin-left: 91.66667%;
  }

  .row > .col-12-mobilep {
    width: 100%;
  }

  .row > .off-12-mobilep {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.5em;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.5em;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.5em;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.5em;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -1em;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 1em;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -1em;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 1em;
  }

  .row {
    margin-top: 0;
    margin-left: -2em;
  }

  .row > * {
    padding: 0 0 0 2em;
  }

  .row.gtr-uniform {
    margin-top: -2em;
  }

  .row.gtr-uniform > * {
    padding-top: 2em;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -3em;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 3em;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -3em;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 3em;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -4em;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 4em;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -4em;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 4em;
  }
}

/* Container */

.container {
  margin: 0 auto;
  width: 1200px;
}

.container.medium {
  width: 900px;
}

.container.narrowcontainer {
  width: 960px;
}


@media screen and (max-width: 1680px) {
  .container {
    width: 1080px;
  }

  .container.medium {
    width: 810px;
  }

  .container.narrowcontainer {
    width: 960px;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    width: 960px;
  }

  .container.medium {
    width: 720px;
  }

  .container.narrowcontainer {
    width: 900px;
  }
}

@media screen and (max-width: 980px) {
  .container {
    width: 90%;
  }

  .container.medium {
    width: 90%;
  }

  .container.narrowcontainer {
    width: 90%;
  }
}

@media screen and (max-width: 736px) {
  .container {
    width: 100%;
  }

  p {
    text-align: left;
  }

  .container.medium {
    width: 100%;
  }

  .container.narrowcontainer {
    width: 100%;
  }
}

/* Section/Article */

section.special,
article.special {
  text-align: center;
}

header.major {
  position: relative;
}

header.major::after {
  background: var(--accent-gray);
  content: '';
  display: inline-block;
  height: 3px;
  margin: 0 0 2em 0;
  width: 6em;
}

header.major.alt {
  text-align: center;
  margin-bottom: 2em;
}

header p {
  letter-spacing: 0.125em;
  margin: 0 0 1.5em 0;
  position: relative;
  text-transform: uppercase;
}

header h2 + p {
  font-size: 1em;
  margin-top: -0.85em;
  line-height: 1.75em;
}

header h3 + p {
  font-size: 0.9em;
  margin-top: -0.75em;
  line-height: 1.75em;
}

header h4 + p,
header h5 + p,
header h6 + p {
  font-size: 0.8em;
  margin-top: -0.75em;
  line-height: 1.75em;
}

/* Form */

form {
  margin: 0 0 2em 0;
}

.fake-label, label {
  color: var(--header-color);
  display: block;
  font-size: 0.9em;
  font-weight: 600;
  
}

label {
  margin: 0 0 1em 0;
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="date"],
input[type="number"].no-number-number,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border: solid 2px var(--accent-gray);
  background-color: var(--form-background);
  border-radius: 0;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;
}

input[type="text"]:invalid,
input[type="search"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
input[type="date"]:invalid,
input[type="number"].no-number-number:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

input[readonly] {
  background: var(--form-disabled);
  font-style: italic;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="email"]:focus,
input[type="date"]:focus,
input[type="number"].no-number-number:focus,
select:focus,
textarea:focus {
  border-color: var(--link-color);
}

input[type="number"].no-number-number{
  appearance: textfield;
}

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23eee' /%3E%3C/svg%3E");
  background-size: 1.25em;
  background-repeat: no-repeat;
  background-position: calc(100% - 1em) center;
  height: 3.5em;
  padding-right: 3.5em;
  text-overflow: ellipsis;
}

select:focus::-ms-value {
  background-color: transparent;
}

select::-ms-expand {
  display: none;
}

select option {
  color: var(--header-color);
  background: var(--page-background);
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="date"],
input[type="number"].no-number-number,
select {
  height: 3.5em;
}

#header nav > ul#react-menu {
  margin: 0 0 0 2em;
}

#header nav > ul > li:hover > ul{
  display: block;
  position: absolute;
  right: 0em;
  top: 3em;
  background: var(--footer-background);
  list-style: none;
  padding: 5px 10px;
}

#header nav > ul > li > ul > li > a {
  display: block;
  line-height: 2.5em;
}

textarea {
  padding: 0.75em 1em;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1;
}

input[type="checkbox"] + label,
input[type="radio"] + label,
input[type="checkbox"] + span {
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 300;
  padding-left: 2.85em;
  padding-right: 0.75em;
  position: relative;
}

input[type="checkbox"] + label::before,
input[type="radio"] + label::before,
input[type="checkbox"] + span::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-weight: 900;
}

input[type="checkbox"] + label::before,
input[type="radio"] + label::before,
input[type="checkbox"] + span::before {
  background: var(--transparent-button-hover);
  border: solid 2px var(--caption-gray);
  content: '';
  display: inline-block;
  font-size: 0.8em;
  height: 2.1em;
  left: 0;
  line-height: 1.925em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 2.1em;
}

input[type="checkbox"]:checked + label::before,
input[type="radio"]:checked + label::before,
input[type="checkbox"]:checked + span::before {
  background: var(--footer-background);
  border-color: var(--footer-background);
  color: #fff;
  /*content: '\f00c';*/
  content: '\2713';
}

input[type="checkbox"]:focus + label::before,
input[type="radio"]:focus + label::before,
input[type="checkbox"]:focus + span::before {
  border-color: var(--link-color);
}

input[type="radio"] + label::before {
  border-radius: 100%;
}

::-webkit-input-placeholder {
  color: #bbb !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bbb !important;
  opacity: 1;
}

::placeholder {
  color: #bbb !important;
  opacity: 1;
}

/* Box */

.box {
  border: solid 2px var(--accent-gray);
  margin-bottom: 2em;
  padding: 1.5em;
}

.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}

.box.alt {
  border: 0;
  padding: 0;
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;

}

.icon > svg{
  height: 32px;
  width: 32px;
  vertical-align: middle;
}

.icon::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

.icon.solid::before {
  font-weight: 900;
}

.icon > .label {
  display: none;
}

/* Image */

.image {
  border: 0;
  display: inline-block;
  position: relative;
}

.image::before {
  background: url("../assets/img/overlay.png");
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.image img {
  display: block;
}

.image.left {
  float: left;
  margin: 0 1.5em 1em 0;
  top: 0.25em;
}

.image.right {
  float: right;
  margin: 0 0 1em 1.5em;
  top: 0.25em;
}

.image.fit {
  display: block;
  margin: 0 0 3em 0;
  width: 100%;
}

.image.fit img {
  display: block;
  width: 100%;
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em;
}

ol li {
  padding-left: 0.25em;
}

ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em;
}

ul li {
  padding-left: 0.5em;
}

ul.alt {
  list-style: none;
  padding-left: 0;
}

ul.alt li {
  border-top: solid 1px var(--accent-gray);
  padding: 0.5em 0;
}

ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

dl {
  margin: 0 0 2em 0;
}

/* Actions */

ul.actions {
  display: flex;
  cursor: default;
  list-style: none;
  margin-left: -1em;
  padding-left: 0;
}

ul.actions li {
  padding: 0 0 0 1em;
  vertical-align: middle;
}

ul.actions.special {
  justify-content: center;
  width: 100%;
  margin-left: 0;
}

ul.actions.special li:first-child {
  padding-left: 0;
}

ul.actions.stacked {
  flex-direction: column;
  margin-left: 0;
}

ul.actions.stacked li {
  padding: 1.3em 0 0 0;
}

ul.actions.stacked li:first-child {
  padding-top: 0;
}

ul.actions.fit {
  width: calc(100% + 1em);
}

ul.actions.fit li {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}

ul.actions.fit li > * {
  width: 100%;
}

ul.actions.fit.stacked {
  width: 100%;
}

@media screen and (max-width: 480px) {
  ul.actions:not(.fixed) {
    flex-direction: column;
    margin-left: 0;
    width: 100% !important;
  }

  ul.actions:not(.fixed) li {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 1em 0 0 0;
    text-align: center;
    width: 100%;
  }

  ul.actions:not(.fixed) li > * {
    width: 100%;
  }

  ul.actions:not(.fixed) li:first-child {
    padding-top: 0;
  }

  ul.actions:not(.fixed) li input[type="submit"],
  ul.actions:not(.fixed) li input[type="reset"],
  ul.actions:not(.fixed) li input[type="button"],
  ul.actions:not(.fixed) li button,
  ul.actions:not(.fixed) li .button {
    width: 100%;
  }

  ul.actions:not(.fixed) li input[type="submit"].icon::before,
  ul.actions:not(.fixed) li input[type="reset"].icon::before,
  ul.actions:not(.fixed) li input[type="button"].icon::before,
  ul.actions:not(.fixed) li button.icon::before,
  ul.actions:not(.fixed) li .button.icon::before {
    margin-left: -0.5em;
  }
}

/* Icons */

ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.icons li {
  display: inline-block;
  padding: 0 1em 0 0;
}

ul.icons li:last-child {
  padding-right: 0;
}

ul.icons li .icon::before {
  font-size: 1.5em;
}

/* Table */

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 2em 0;
  width: 100%;
}

table tbody tr {
  border: solid 1px var(--accent-gray);
  border-left: 0;
  border-right: 0;
}

table tbody tr:nth-child(2n + 1) {
  background-color: rgba(144, 144, 144, 0.05);
}

table td {
  padding: 0.75em 0.75em;
}

table th {
  color: var(--header-color);
  font-size: 0.9em;
  font-weight: 600;
  padding: 0 0.75em 0.75em 0.75em;
  text-align: left;
}

table thead {
  border-bottom: solid 2px var(--accent-gray);
}

table tfoot {
  border-top: solid 2px var(--accent-gray);
}

table.alt {
  border-collapse: separate;
}

table.alt tbody tr td {
  border: solid 1px var(--accent-gray);
  border-left-width: 0;
  border-top-width: 0;
}

table.alt tbody tr td:first-child {
  border-left-width: 1px;
}

table.alt tbody tr:first-child td {
  border-top-width: 1px;
}

table.alt thead {
  border-bottom: 0;
}

table.alt tfoot {
  border-top: 0;
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button,
.wp-block-button{
  -webkit-appearance: none;
  appearance: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: var(--footer-background);
  border-radius: 0;
  border: 0;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9em;
  font-weight: 600;
  
  letter-spacing: 0.125em;
  overflow: hidden;
  padding: 0.75em 1.75em;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;

}

/*
  white-space: nowrap;
  line-height: 4em;
  height: 4em;
*/

.wp-block-button__link {
  text-decoration: none;
}

.wp-block-button:not(.is-style-outline) .wp-block-button__link:not(.has-background){
  background: none !important;
}

.wp-block-button__link:not(.has-text-color){
  box-shadow: none !important;
}

.wp-block-button .wp-block-button__link{
  border: none !important;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover,
.wp-block-button:hover {
  background-color: var(--button-hover);
}

input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
button:active,
.button:active,
.wp-block-button:active {
  background-color: #121818;
}

input[type="submit"].icon,
input[type="reset"].icon,
input[type="button"].icon,
button.icon,
.button.icon,
.wp-block-button.icon {
  padding-left: 2.35em;
}

input[type="submit"].icon::before,
input[type="reset"].icon::before,
input[type="button"].icon::before,
button.icon::before,
.button.icon::before, 
.wp-block-button.icon::before {
  margin-right: 0.5em;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit,
.wp-block-button.fit {
  width: 100%;
}

input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
button.small,
.button.small,
.wp-block-button.small {
  font-size: 0.8em;
  height: 3.5em;
  line-height: 3.5em;
  padding: 0 2em;
}

input[type="submit"].large,
input[type="reset"].large,
input[type="button"].large,
button.large,
.button.large,
.wp-block-button.large {
  font-size: 1.25em;
  height: 3.15em;
  line-height: 3.15em;
}

input[type="submit"].alt,
input[type="reset"].alt,
input[type="button"].alt,
button.alt,
.button.alt,
.wp-block-button.alt {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--accent-gray);
  color: var(--header-color) !important;
}

input[type="submit"].alt:hover,
input[type="reset"].alt:hover,
input[type="button"].alt:hover,
button.alt:hover,
.button.alt:hover,
.wp-block-button.alt::hover  {
  background-color: var(--transparent-button-hover);
}

input[type="submit"].alt:active,
input[type="reset"].alt:active,
input[type="button"].alt:active,
button.alt:active,
.button.alt:active,
.button.alt:active  {
  background-color: rgba(144, 144, 144, 0.1);
}

input[type="submit"].alt.icon::before,
input[type="reset"].alt.icon::before,
input[type="button"].alt.icon::before,
button.alt.icon::before,
.button.alt.icon::before,
.wp-block-button.alt.icon::before  {
  color: #bbb;
}

input[type="submit"].primary,
input[type="reset"].primary,
input[type="button"].primary,
button.primary,
.button.primary,
.wp-block-button.primary {
  background-color: var(--link-color);
  color: #fff !important;
}

input[type="submit"].primary:hover,
input[type="reset"].primary:hover,
input[type="button"].primary:hover,
button.primary:hover,
.button.primary:hover,
.wp-block-button.primary:hover {
  background-color: #ec7d75;
}

input[type="submit"].primary:active,
input[type="reset"].primary:active,
input[type="button"].primary:active,
button.primary:active,
.button.primary:active,
.wp-block-button.primary:active {
  background-color: #e65349;
}

input[type="submit"].disabled,
input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled,
.wp-block-button:disabled {
  background-color: #4a4a4a !important;
  box-shadow: inset 0 -0.15em 0 0 rgba(0, 0, 0, 0.15);
  color: #fff !important;
  cursor: default;
  opacity: 0.25;
}

.wp-block-button .wp-block-button__link{
  font-weight: inherit !important;
  font-size: inherit !important;
}

.wp-block-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  margin-bottom: 28px;
}

/* Feature */

.feature .svg-inline--fa{
  font-size: 40px;
  margin-bottom: 15px;
}

.feature {
  margin: 0 0 2em 0;
  padding: 2em 0 2em 9em;
  position: relative;
}

.feature i {
  border: solid 3px var(--accent-gray);
  color: var(--feature-color);
  display: block;
  height: 7em;
  left: 0;
  line-height: 7.25em;
  margin-top: -0.5em;
  position: absolute;
  text-align: center;
  top: 2em;
  width: 7em;
}

.feature i::before {
  font-size: 40px;
}

.feature h3 {
  margin-bottom: 0.5em;
}

.feature p {
  margin-bottom: 0;
}

/* Image Feature */

.image-feature {
  position: relative;
  text-align: center;
  margin: 0 0.5em 2em 0.5em;
  overflow: hidden;
}

.image-feature > a {
  display: block;
}

.image-feature header {
  background: var(--feature-header);
  left: 0;
  padding: 0.8em 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.image-feature header::before {
  bottom: -0.69em;
  content: '';
  display: block;
  height: 0;
  left: 50%;
  margin-left: -0.7em;
  position: absolute;
  width: 0;
  border-left: .7em solid transparent;
  border-right: .7em solid transparent;
  border-top: .7em solid var(--feature-header);
}



.image-feature header h3 {
  font-size: 1em;
  margin: 0;
}

.image-feature .image {
  margin: 0;
}

.image-feature p {
  margin: 0;
}

.image-feature footer {
  background: var(--feature-footer);
  bottom: 0;
  color: var(--feature-footer-text);
  left: 0;
  padding: 0.5em 1.5em;
  position: absolute;
  width: 100%;
  z-index: 1;
}

@media (hover: hover) {
  .image-feature footer {
    bottom: -200px;
    transition: bottom 0.3s ease-in-out;
  }

  .image-feature:hover footer {
    bottom: 0;
  }
}
.image-feature a{
  color: white;
  text-decoration: none;
}

.image-feature footer input[type="submit"].alt,
.image-feature footer input[type="reset"].alt,
.image-feature footer input[type="button"].alt,
.image-feature footer button.alt,
.image-feature footer .button.alt {
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.25);
  color: #fff !important;
}

/* Wrapper */

.wrapper {
  padding: 6em 0 4em 0;
  transition: background 0.2s ease-in-out;
}

.wrapper.style1 {
  background: var(--link-color);
  color: #d7f2e5;
}

.wrapper.style1 h2,
.wrapper.style1 h3,
.wrapper.style1 h4,
.wrapper.style1 h5,
.wrapper.style1 h6,
.wrapper.style1 strong {
  color: #fff;
}

.wrapper.style1 header.major::after {
  background: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 {
  background: var(--page-background);
}

.wrapper.style3 {
  background: var(--page-background-alt);
}

/* Header */

#header {
  background: var(--footer-background);
  color: #fff;
  cursor: default;
  height: 3.25em;
  left: 0;
  line-height: 3.25em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

#header h1 {
  color: #fff;
  height: inherit;
  left: 1.25em;
  line-height: inherit;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
}

#header h1 a {
  font-size: 1em;
}

#header h1 a::before {
  color: var(--link-color);
  margin-right: 0.35em;
}

#header nav {
  font-weight: 600;
  height: inherit;
  letter-spacing: 0.125em;
  line-height: inherit;
  position: absolute;
  right: 1.5em;
  text-transform: uppercase;
  top: 0;
  vertical-align: middle;
}

#header nav > ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

#header nav > ul > li {
  display: inline-block;
  margin-left: 2em;
  padding-left: 0;
}

#header nav > ul > li a {
  transition: color 0.2s ease-in-out;
  color: var(--nav-text);
  display: inline-block;
  text-decoration: none;
  font-size: 0.8em;
}

/*
#header nav > ul > li.calltoaction a {
  color: #920f1e;
}
*/

#header nav > ul > li a::before, #header nav .svg-inline--fa {
  margin-right: 0.5em;
  opacity: 0.5;
}

#header nav > ul > li a:hover {
  color: var(--footer-social-hover);
}

#header nav > ul > li.active > a {
  color: var(--footer-social-hover);
}

#header nav > ul > li:first-child {
  margin-left: 0;
}

#header nav > ul > li input[type="submit"],
#header nav > ul > li input[type="reset"],
#header nav > ul > li input[type="button"],
#header nav > ul > li button,
#header nav > ul > li .button {
  font-size: 1em;
  height: 2.25em;
  line-height: 2.25em;
  margin-bottom: 0;
  padding: 0 1em;
  position: relative;
  top: -0.125em;
  vertical-align: middle;
}

#header nav > ul > li > ul {
  display: none;
}

#header .container {
  position: relative;
}

#header .container h1 {
  left: 0;
}

#header .container nav {
  right: 0;
}

.dropotron {
  background: var(--footer-background);
  color: var(--footer-social-hover);
  font-weight: 600;
  letter-spacing: 0.125em;
  list-style: none;
  margin-top: -1em;
  min-width: 14em;
  padding: 1.25em 0;
  text-transform: uppercase;
}

.dropotron.level-0 {
  font-size: 0.8em;
  margin-top: 1em;
}

.dropotron.level-0::before {
  transform: rotate(45deg);
  background: var(--footer-background);
  content: '';
  display: block;
  height: 1em;
  position: absolute;
  right: 1.5em;
  top: -0.5em;
  width: 1em;
}

.dropotron li > a {
  transition: color 0.2s ease-in-out;
  color: var(--nav-text);
  display: block;
  padding: 0.125em 1.25em;
  text-decoration: none;
}

.dropotron li > a:hover {
  color: var(--footer-social-hover);
}

.dropotron li.active > a {
  color: var(--footer-social-hover);
}

/* Banner */

#banner {
  background-color: transparent;
  /*background-attachment: scroll, fixed;
  background-image: url("../assets/img/overlay.png"), url("../assets/img/fire.jpg");
  background-image: url("../assets/img/overlay.png"), image-set(
    url("../assets/img/fire.jpg.webp") type("image/webp"),
    url("../assets/img/fire.jpg") type("image/jpeg")
    );

  background-position: top left, center center;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;*/
  color: #fff;
  padding: 12em 0;
  position: relative;
  text-align: center;
  overflow: hidden;
}

#banner::after {
  transition: opacity 2s ease-in;
  background: #1c1e1e;
  content: '';
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

#banner :last-child {
  margin-bottom: 0;
}

#banner .inner {
  /* background: #1d2726;
  background: rgba(29, 39, 38, 0.9); */
  display: inline-block;
  padding: 3.5em 5em;
  position: relative;
  z-index: 2;
}

#banner .inner h2 {
  color: white;
  font-size: 2.5em;
  line-height: 1.35em;
  margin: 0;
  padding: 0;
}

#banner .inner p {
  font-size: 1em;
  color: rgba(255,255,255,0.7);
  line-height: 2em;
  margin: 2em 0 0 0;
  padding: 2em 0 0 0;
  position: relative;
  text-transform: uppercase;
  top: -0.25em;
  font-weight: 600;
  letter-spacing: 0.065em;
  font-size: 1.5em;
  text-align: center;
}

#banner .inner p::before {
  background: rgba(255,255,255,0.7);
  border-radius: 1px;
  content: '';
  display: block;
  height: 3px;
  left: 50%;
  margin: 0 0 0 -8em;
  position: absolute;
  top: 0;
  width: 16em;
}

#banner .inner .actions {
  margin-top: 2em;
}

#banner .inner input[type="submit"].alt,
#banner .inner input[type="reset"].alt,
#banner .inner input[type="button"].alt,
#banner .inner button.alt,
#banner .inner .button.alt {
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.25);
  color: #fff !important;
}

body.is-preload #banner::after {
  opacity: 1;
}

/* Main */

#main > header.major {
  margin: 0 0 2em 0;
  text-align: center;
}

/* One */

#one {
  padding-bottom: 0;
}

#one p {
  font-size: 1.25em;
  line-height: 1.75em;
  margin: -0.5em 0 0 0;
}

#one .inner {
  display: inline-block;
  margin-top: -4em;
  padding-right: 4em;
  vertical-align: middle;
  width: 50%;
}

#one .device {
  border: solid 20px #1b1b1b;
  border-bottom: 0;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  vertical-align: bottom;
  width: 50%;
}

#one .device::after {
  background: #000;
  border-radius: 100%;
  content: '';
  height: 10px;
  left: 50%;
  margin: -14px 0 0 -5px;
  position: absolute;
  top: 0;
  width: 10px;
}

/* Footer */

#footer {
  background: var(--footer-background);
  transition: background 0.2s ease-in-out;
  color: var(--footer-text);
  padding: 4em 0;
  text-align: center;
}

#footer .icons {
  padding-left: 0;
}

#footer .icons li {
  padding: 0 2em 0 0;
}

#footer .icons li:last-child {
  padding-right: 0;
}

#footer .icons li a {
  transition: color 0.2s ease-in-out;
  color: var(--footer-social);
  transition: color 0.2s ease-in-out;
}

#footer .icons li a:hover {
  color: var(--footer-social-hover);
}

#footer .copyright {
  line-height: 1.4em;
  font-size: 0.9em;
  margin: 2em 0 0 0;
  max-width: 700px;
  margin: 0 auto;
}

#footer .copyright a {
  color: var(--footer-text);
  transition: color 0.2s ease-in-out;
}

/* Wide */

@media screen and (max-width: 1680px) {
  /* Basic */

  body,
  input,
  select,
  textarea {
    font-size: 11pt;
  }

  .textLogo {
    margin: 6px 0 0 0;
  }
}

/* Normal */

@media screen and (max-width: 1280px) {
  /* Basic */

  body,
  input,
  select,
  textarea {
    font-size: 11pt;
  }

  /* Feature */

  .feature {
    padding-left: 7em;
  }

  .feature i {
    height: 5.25em;
    line-height: 5.5em;
    margin-top: 0;
    width: 5.25em;
  }

  .feature i::before {
    font-size: 32px;
  }

  /* Wrapper */

  .wrapper {
    padding: 4em 0 2em 0;
  }

  /* Banner */

  #banner {
    background-attachment: scroll;
    padding: 8em 0;
  }

  #banner .inner {
    padding: 3em 4em;
  }

  #banner .inner h2 {
    font-size: 2.25em;
  }

  /* Main */

  #main {
    padding: 2em 0;
  }

  /* One */

  #one .inner {
    margin-top: -3em;
    padding-right: 4em;
  }

  #one .device {
    border-width: 18px;
  }

  #one .device::after {
    margin-top: -14px;
  }
}

/* Narrow */

#navPanel,
#navButton {
  display: none;
}

@media screen and (max-width: 980px) {
  /* Basic */
  
  #banner .inner p {
    font-size: 1.0em;
  }

  html,
  body {
    overflow-x: hidden;
  }

  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }

  h2 {
    font-size: 1.25em;
    line-height: 1.75em;
  }

  h3 {
    font-size: 1.1em;
    line-height: 1.5em;
  }

  h4 {
    font-size: 1em;
    line-height: 1.5em;
  }

  /* Section/Article */

  section.special,
  article.special {
    text-align: center;
  }

  header h2 + p {
    font-size: 0.9em;
  }

  header h3 + p {
    font-size: 0.8em;
  }

  header h4 + p,
  header h5 + p,
  header h6 + p {
    font-size: 0.8em;
  }

  /* Feature */

  .feature {
    padding-left: 9em;
  }

  .feature i {
    height: 7em;
    line-height: 7.5em;
    margin-top: -0.5em;
    width: 7em;
  }

  .feature i::before {
    font-size: 42px;
  }

  /* Image Feature */

  .image-feature .image {
    max-height: 30em;
    overflow: hidden;
  }

  /* Header */

  #page-wrapper {
    padding-top: 0;
  }

  #header nav > ul > li {
    display: block;
    margin-left: 0;
  }

  #header nav > ul > li > a {
    color: white;
  }

  #header nav{
    position: absolute;
    left: 0;
    right: unset;
  }

  #header nav .fa-angle-down{
    display: none;
  }

  #header nav > ul#react-menu{
    margin: 3.2em 0 0 0;
    height: calc(100vh - 3.2em);
    transform: translateX(-300px);
    transition: transform 0.5s ease;
    padding: 0 1.5em;
    min-width: 275px;
    overflow-y: scroll;
  }

  #header nav > ul > li > ul{
    display: block;
    margin: 0;
  }

  #header nav > ul > li:hover > ul, #header nav > ul > li:active > ul{
    display: block;
    position: unset;
    left: unset;
    top: unset;
    padding: 0 0 0 1em;
  }

  #header nav > ul > li > ul > li, #header nav > ul > li{
    list-style: none;
    line-height: 2.5em;
    border-top: 1px solid hsla(0,0%,100%,.05);
  }

  #header nav > ul > li > ul > li > a, #header nav > ul > li > a{
    line-height: inherit;
    letter-spacing: 0.125em;
  }

  #header nav > ul {
    background:  var(--footer-background);
  }

  #header nav > ul#react-menu.menu-open{
    transform: translateX(0);
  }

  #header h1 {
    left: unset;
    right: 1.25em;
    top: 2px;
  }

  /* Banner */

  #banner {
    padding: 10em 0;
  }

  #banner .inner {
    padding: 3em;
  }

  #banner .inner h2 {
    font-size: 2em;
  }

  /* Main */

  #main > header.major {
    margin: 0;
  }

  /* One */

  #one p {
    font-size: 1em;
  }

  #one .inner {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    text-align: center;
    width: 35em;
  }

  #one .device {
    display: block;
    margin: 4em auto 0 auto;
    max-width: 100%;
    width: 30em;
  }

  /* Off-Canvas Navigation */

  #page-wrapper {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.5s ease;
    padding-bottom: 1px;
  }

  #navButton {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.5s ease;
    display: block;
    height: 4em;
    left: 0;
    position: fixed;
    top: 0;
    width: 6em;
    z-index: 10001;
  }

  #navButton .toggle {
    text-decoration: none;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 13px;
    color: white;
    font-size: 24px;
  }
/*
  #navButton .toggle::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-transform: none !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }

  #navButton .toggle::before {
    background: var(--footer-background);
    color: #fff;
    content: '\f0c9';
    display: block;
    font-size: 18px;
    height: 2.35em;
    left: 0.5em;
    line-height: 2.35em;
    position: absolute;
    text-align: center;
    top: 0.5em;
    width: 3em;
  }
*/
  #navPanel {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateX(-275px);
    transition: transform 0.5s ease;
    background-image: linear-gradient(left, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.1));
    display: block;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 275px;
    z-index: 10002;
    background: var(--footer-background);
    color: #c7c9c9;
    padding: 0.25em 1.5em;
  }

  #navPanel .link {
    border-top: solid 1px rgba(255, 255, 255, 0.05);
    color: #c7c9c9;
    display: block;
    font-size: 0.8em;
    font-weight: 600;
    height: 3.5em;
    letter-spacing: 0.125em;
    line-height: 3.5em;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #navPanel .link:first-child {
    border-top: 0;
  }

  #navPanel .link.depth-0, #nav > ul > li > a {
    color: #fff;
  }

  #navPanel .link .indent-1 {
    display: inline-block;
    width: 1.25em;
  }

  #navPanel .link .indent-2 {
    display: inline-block;
    width: 2.5em;
  }

  #navPanel .link .indent-3 {
    display: inline-block;
    width: 3.75em;
  }

  #navPanel .link .indent-4 {
    display: inline-block;
    width: 5em;
  }

  #navPanel .link .indent-5 {
    display: inline-block;
    width: 6.25em;
  }

  body.navPanel-visible #page-wrapper {
    transform: translateX(275px);
  }

  body.navPanel-visible #navButton {
    transform: translateX(275px);
  }

  body.navPanel-visible #navPanel {
    transform: translateX(0);
  }
}

/* Mobile */

@media screen and (max-width: 736px) {
  /* Basic */

  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }

  /* Feature */

  .feature {
    margin: 0 auto 2em auto;
    max-width: 100%;
    padding: 0;
    text-align: center;
    width: 30em;
  }

  .feature i {
    left: 0;
    margin: 0 auto 2em auto;
    position: relative;
    top: 0;
  }

  /* Image Feature */

  .image-feature {
    margin: 0 auto 2em auto;
    max-width: 100%;
    width: 25em;
  }

  .image-feature .image {
    max-height: none;
  }

  /* Wrapper */

  .wrapper {
    padding: 2.5em 1.25em 0.5em 1.25em;
  }

  /* Banner */

  #banner {
    padding: 6em 0;
  }

  #banner .inner {
    padding: 2em 2.5em;
    width: 100%;
  }

  #banner .inner h2 {
    font-size: 1.5em;
  }

  #banner .inner p {
    margin: 1.5em 0 0 0;
    padding: 1.5em 0 0 0;
  }

  /* One */

  #one .device {
    border-width: 20px;
    margin-top: 2em;
    max-width: 100%;
    width: 25em;
  }

  #one .device::after {
    height: 8px;
    margin: -13px 0 0 -4px;
    width: 8px;
  }
}

@media screen and (max-width: 550px) {
  .row > .col-12-narrow {
    width: 100%;
  }
}

/* Mobile (Portrait) */

@media screen and (max-width: 480px) {
  /* Basic */

  html,
  body {
    min-width: 320px;
  }

  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }

  h1 br,
  h2 br,
  h3 br,
  h4 br,
  h5 br,
  h6 br {
    display: none;
  }

  h2 {
    font-size: 1.15em;
    line-height: 1.75em;
  }

  h3 {
    font-size: 1em;
    line-height: 1.5em;
  }

  /* List */

  ul.actions {
    margin: 0 0 2em 0;
  }

  ul.actions li {
    display: block;
    padding: 1em 0 0 0;
    text-align: center;
    width: 100%;
  }

  ul.actions li:first-child {
    padding-top: 0;
  }

  ul.actions li > * {
    margin: 0 !important;
    width: 100%;
  }

  ul.actions li > *.icon::before {
    margin-left: -2em;
  }

  ul.actions.small li {
    padding: 0.5em 0 0 0;
  }

  ul.actions.small li:first-child {
    padding-top: 0;
  }

  /* Button */

  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button,
  .button {
    padding: 0;
  }

  /* One */

  #one .device {
    border-width: 15px;
  }

  #one .device::after {
    height: 5px;
    margin: -10px 0 0 -2.5px;
    width: 5px;
  }

  .image-feature footer {
    font-size: 10pt;
    line-height: 1.5;
  }

}


.vorlagen-block {
  padding: 15px;
}

@media (max-width: 920px) {
  #vorlagen-offer{
    flex-direction: column-reverse;
  }

  .vorlagen-block, #vorlagen-offer .vorlagen-block:last-child{
    margin: 0 auto 15px auto;
    max-width: 400px;
  }
}

.longbutton{
  font-size: 0.7em;
  letter-spacing: 0.08em;
}

p.has-text-align-center {
  text-align: center;
}

.wp-block-group.has-background{
  display: inline-block;
}

main.bloggrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 15px;
  grid-auto-flow: dense;
  text-align: left;
}

.bloggrid .wp-block-image figure.alignleft, .bloggrid .wp-block-image figure.alignright{
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.bloggrid p{
  text-align: left;
}

.bloggrid article{
  overflow: hidden;
  margin-bottom: 0;
  padding: 10px;
}

.bloggrid article h3{
  overflow: hidden;
  font-size: 1.1em;
  letter-spacing: 0.08em;
}

.bloggrid article.fullwidth{
  grid-column: 1 / -1;
  background: var(--footer-background);
  color: var(--footer-text);
  padding: 40px 40px 10px 40px;
}

.fullwidth h3{
  color: var(--footer-text);
}

/* Extra-wide grid-posts */
.bloggrid-firstpage article:nth-child(31n + 1) {
grid-column: 1 / -1;
}

.bloggrid-archivepage article:nth-child(31n + 2) {
  grid-column: 2 / -1;
  }

.bloggrid-firstpage article:nth-child(16n + 2) {
grid-column: -3 / -1;
background: var(--link-color);
color: #FFF;
}
.bloggrid article:nth-child(6n + 6) {
grid-column: 1 / -2;
}

/* Extra-wide grid-posts */
.bloggrid article:nth-child(31n + 1) h3{
font-size: 1.25em;
letter-spacing: 0.125em;
}
.bloggrid article:nth-child(16n + 2) h3{
font-size: 1.25em;
letter-spacing: 0.125em;
color: white;
}

.bloggrid article:nth-child(16n + 2) a{
 color: white;
}

.bloggrid-firstpage article:nth-child(16n + 2) footer{
 color: #ffa6a6;
}

.bloggrid article:nth-child(6n + 6) h3{
font-size: 1.25em;
letter-spacing: 0.125em;
}

/* Single column display for phones */
@media (max-width: 560px) {
main.bloggrid {
  display: flex;
  flex-direction: column;
}
}

.bloggrid figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.bloggrid figcaption {
  display: none;
}

.fullwidth img{
  float: right;
  border: 1px solid white;
  margin: 10px;
}

@import url("./twentytwelve.css");

.legal-links li{
  display: inline-block;
  list-style: none;
}

.legal-links li a{
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 0.8em;
}

.legal-links li a:hover{
  text-decoration: underline;
}

.copyright p{
  margin-bottom: .3em;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .user-table {
    font-size: 8pt;
    display: block;
    overflow-x: auto;
  }  
}

.sf-minitoolbar button{
  height: auto;
  line-height: normal;
}

svg {
  pointer-events: none;
}

.checkout-step {
  margin-top: 3rem;
}


.parallax {
  perspective: 1px;
  margin-top: 3.25em;
  height: calc(100vh - 3.25em);
  overflow-x: hidden;
  overflow-y: auto;
}
.parallax__layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.parallax__layer--base {
  transform: translateZ(0);
  min-height: 100vh;
}
.parallax__layer--back {
  background-color: black;
  transform: translateZ(-1px) scale(2);
  background-image: url("../assets/img/overlay.png"), url("../assets/img/fire.jpg");
  background-image: url("../assets/img/overlay.png"), image-set(
    url("../assets/img/fire.jpg.webp") type("image/webp"),
    url("../assets/img/fire.jpg") type("image/jpeg")
    );
  background-position: center 0px;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  margin-left: -1em;
}

.page-container{
  min-height: calc(100vh - 16em);
}

@media screen and (max-width: 1680px) {
  .parallax__layer--back {
    background-position: center 0px;
    transform: translateZ(-1px) scale(2);
    min-height: 791px;
  }
}


@media screen and (max-width: 1280px) {
  .parallax__layer--back {
    background-position: center 0px;
    transform: translateZ(-1px) scale(2);
    min-height: 791px;
  }
}

@media screen and (max-width: 980px) {
  .parallax__layer--back {
    background-position: center 0px;
    transform: translateZ(-1px) scale(2);
    min-height: 680px;
  }
}


@media screen and (max-width: 736px) {
  .parallax__layer--back {
    background-position: top left, center top;
    transform: translateZ(-1px) scale(2);
    min-height: 435px;
  }
}

.small-margin-after {
	margin-bottom: 12px;
}

.medium-margin-after {
	margin-bottom: 30px;
}

.top-price-section{
  height: 180px;
}

.top-price-section + .wp-block-buttons{
  margin: 0 !important;
}

.block{
  display: block;
}

#nav > ul {
  display: inline-block;
}

:root {
  --primary: red;
}

.entry-content p{
  hyphens: auto;
}

#one p.header-footnote{
  margin: 1em 0 1em 0;
  font-size: 1em;
}

.visible-link{
  text-decoration: underline;
}